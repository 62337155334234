








import Vue from 'vue'
import { BaseCard, BaseCardBody } from '@/components'
import { Candidates } from './components'

export default Vue.extend({
    name: 'AllCandidates',

    components: { Candidates, BaseCard, BaseCardBody },
})
