










import Vue from 'vue'
import { ListItemCandidate } from './components'
import { ICandidate } from '@/types'

export default Vue.extend({
    name: 'CandidatesList',

    components: { ListItemCandidate },

    props: {
        candidates: {
            type: Array as () => ICandidate[],
            required: true,
        },
    },
})
