

































import Vue from 'vue'
import { ICandidate } from '@/types'
import { BaseSpinner } from '@/components'
import { DetailsItem } from './components'

export default Vue.extend({
    name: 'CandidateDetails',

    components: { BaseSpinner, DetailsItem },

    props: {
        candidate: {
            type: Object as () => ICandidate,
            required: true,
        },
    },

    computed: {
        noticePeriod(): string {
            if (Number(this.candidate.noticePeriod) === 1)
                return this.candidate.noticePeriod + ' month'
            if (Number(this.candidate.noticePeriod) > 6)
                return this.candidate.noticePeriod + ' days'
            return 'no notice period'
        },
    },
})
