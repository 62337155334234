









import Vue from 'vue'
import { IStateDashboardCandidates, ICandidate } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { BaseSpinner } from '@/components'
import { CandidatesList } from './components'
import { ACTION_DASHBOARD_CANDIDATES, STORE_MODULE } from '@/enums'

const { mapState, mapActions } = createNamespacedHelpers(STORE_MODULE.DASHBOARD_CANDIDATES)

export default Vue.extend({
    name: 'Candidates',

    components: { BaseSpinner, CandidatesList },

    computed: {
        ...(mapState(['candidates']) as MapStateToComputed<IStateDashboardCandidates>),
    },

    created() {
        this.fetchAndSetCandidates()
    },

    data() {
        return {
            showCandidateDetail: false,
            fields: [{ key: 'name' }, { key: 'action' }],
        }
    },

    methods: {
        ...mapActions({
            fetchAndSetCandidates: ACTION_DASHBOARD_CANDIDATES.FETCH_AND_SET_CANDIDATES,
        }),
    },
})
