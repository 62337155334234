import { render, staticRenderFns } from "./CandidateDetails.vue?vue&type=template&id=f07d0702&scoped=true&"
import script from "./CandidateDetails.vue?vue&type=script&lang=ts&"
export * from "./CandidateDetails.vue?vue&type=script&lang=ts&"
import style0 from "./CandidateDetails.vue?vue&type=style&index=0&id=f07d0702&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f07d0702",
  null
  
)

export default component.exports