





















import Vue from 'vue'
import { ICandidate } from '@/types'
import { getFormattedFullName } from '@/utils'
import { faIdBadge } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { BaseListItem } from '@/components'
import { CandidateDetails, CandidateProps } from './components'
export default Vue.extend({
    name: 'ListItemCandidate',

    components: { BaseListItem, CandidateProps, CandidateDetails },

    props: {
        candidate: {
            type: Object as () => ICandidate,
            required: true,
        },
    },

    data: () => ({
        isExpanded: false,
        faIdBadge,
    }),

    computed: {
        fullName(): string {
            let jobTitle = ' '
            if (this.candidate.jobTitle) jobTitle += `(${this.candidate.jobTitle})`

            return getFormattedFullName(this.candidate) + jobTitle
        },

        toggleIcon(): IconDefinition {
            return this.isExpanded ? faChevronUp : faChevronDown
        },
    },
})
