
















import Vue from 'vue'
import { ICandidate, CandidateJobStatus } from '@/types'
import { faClock, faCalendarCheck, faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { faEye, faTasks, faRoute, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { BaseListItemProps, BaseListItemProp } from '@/components'

export default Vue.extend({
    name: 'CandidateProps',

    components: { BaseListItemProps, BaseListItemProp },

    props: {
        candidate: {
            type: Object as () => ICandidate,
            required: true,
        },
    },

    data: () => ({
        faClock,
        faCalendarCheck,
        faPaperPlane,
        faEye,
        faTasks,
        faRoute,
        faMoneyBill,
    }),

    computed: {
        candidateStatus(): string {
            return CandidateJobStatus[this.candidate.jobStatus]
        },
        noticePeriod(): string {
            if (Number(this.candidate.noticePeriod) === 1)
                return this.candidate.noticePeriod + ' month'
            if (Number(this.candidate.noticePeriod) > 6)
                return this.candidate.noticePeriod + ' days'
            return 'no notice period'
        },
    },
})
